<template>
  <div class="promocode-view">
    <add-update-logs />
  </div>
</template>

<script>
import AddUpdateNew from '@/components/UpdateNew/AddUpdateNew'

export default {
  name: 'AddUpdateLogsView',
  components: {
    AddUpdateLogs
  }
}
</script>

<style>

</style>
